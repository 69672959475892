<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button
        class="right"
        style="width: 88px; height: 36px; margin-top: -10px; line-height: 0;"
        @click="$router.go(-1)"
        >返回</el-button
      >
    </div>
    <!--主体内容-->
    <div class="content">
      <TitleModule title="基本信息"></TitleModule>
      <info-table
        :num="3"
        :tableData="basicInfo"
        :dataValue="reInline"
        nameStyle="width: 100px;"
        valueStyle="width: calc(100% - 50%); text-align: left; padding-left: 30px;"
      ></info-table>
      <TitleModule title="处罚记录展示" style="margin-top: 20px;"></TitleModule>
      <el-row :gutter="20">
        <el-col :span="24">
          统计时间：{{ this.$route.query.dataDate }}
          <div
            id="auditStatisticsEcharts"
            style="width: 100%; height: 300px;"
          ></div>
        </el-col>
      </el-row>
      <!--列表区域-->
      <TitleModule title="处罚记录"></TitleModule>
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="稽查编号">
            <el-input v-model="formInline.InspectionNumber"></el-input>
          </el-form-item>
          <el-form-item label="上报车场">
            <el-autocomplete
              v-model="parkId"
              class="inline-input"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="parkName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Violation_type')">
            <el-select
              v-model.trim="formInline.violationType"
              filterable
              placeholder="请选择"
            >
              <el-option label="全部" value></el-option>
              <el-option
                :label="value.inspectionTypeDesc"
                :value="value.inspectionTypeCode + ''"
                :key="value.inspectionTypeCode"
                v-for="value in inspectionType"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Report_time')" prop="pdaManagerName">
            <!-- :picker-options="pickerOptions" -->
            <el-date-picker
              v-model="datetime"
              :clearable="false"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              @change="picktime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <!-- <el-button type="primary" icon="el-icon-search" @click="page=1;searchData()"  :loading="loading" v-if='$route.meta.authority.button.query'>{{ $t('button.search') }}</el-button> -->
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          
          :data="tableData"
          style="width: 100%; font-size: 12px;"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTable";
import TitleModule from "@/components/titleModule/titleModule";
import moment from "moment";
export default {
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      inspectionType: [],
      msg: "",
      loading: false,
      datetime: [],
      page: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      formInline: {
        InspectionNumber: "",
        park: "",
        violationType: "",
      },
      tableCols: [
        {
          prop: "checkCode",
          label: "稽查编号",
          width: "",
        },
        {
          prop: "parkName",
          label: "上报车场",
          width: "",
        },
        {
          prop: "checkName",
          label: this.$t("list.Violation_type"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            return cellValue;
          },
        },
        {
          prop: "dataDate",
          label: this.$t("list.Report_time"),
          width: "",
        },
      ],
      reInline: {},
      basicInfo: [
        {
          name: "管理员姓名",
          key: "pdaManagerName",
        },
        {
          name: "登录名",
          key: "account",
        },
        {
          name: "性别",
          key: "sex",
          format: (dataObj, data, dataVal) => {
            // dataObj 是当前对象
            return `<div>
                            ${dataVal.sex == 1 ? "男" : "女"}
                        </div>`;
          },
        },
        {
          name: "手机号",
          key: "mobile",
        },
        {
          name: "角色",
          key: "pdaManagerRole",
          format: (dataObj, data, dataVal) => {
            return `<div>
                            ${
                              dataVal.pdaManagerRole == 1
                                ? "停车巡检员"
                                : "停车稽查员"
                            }
                        </div>`;
          },
        },
        {
          name: "状态",
          key: "pdaManagerState",
          format: (dataObj, data, dataVal) => {
            // dataObj 是当前对象
            return `<div style='color: red'>
                            ${dataObj == 1 ? "在职" : "离职"}
                        </div>`;
          },
        },
        {
          name: "管辖区域",
          key: "pdaManagerAreaName",
          col: 3,
        },
      ],
      parkId: "",
      echartsArr: [],
      legData: [],
    };
  },
  methods: {
    // 选中车场
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // 搜索车场
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 违规类型
    getObeyList() {
      this.$axios
        .get("/acb/2.0/inspectionType/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.inspectionType = res.value;
        });
    },
    picktime(date) {
      if (this.datetime) {
        if (this.$route.query.dataDate.length > 4) {
          let arTime = [
            new Date(
              moment([this.$route.query.dataDate.split("-")[0], 0, 1])
                .month(this.$route.query.dataDate.split("-")[1] - 1)
                .format("YYYY-MM-DD 00:00:00")
            ),
            new Date(
              moment([this.$route.query.dataDate.split("-")[0], 0, 31])
                .month(this.$route.query.dataDate.split("-")[1] - 1)
                .format("YYYY-MM-DD 23:59:59")
            ),
          ];
          let startDate = new Date(date[0]).getTime();
          let endDate = new Date(date[1]).getTime();
          if (startDate < arTime[0].getTime() || endDate > new Date(arTime[1]).getTime()) {
				this.datetime = arTime;
				let str = `请选择时间 ${moment([
				  this.$route.query.dataDate.split("-")[0],
				  0,
				  1,
				])
				  .month(this.$route.query.dataDate.split("-")[1] - 1)
				  .format("YYYY-MM-DD")} ~ ${moment([
				  this.$route.query.dataDate.split("-")[0],
				  0,
				  31,
				])
				  .month(this.$route.query.dataDate.split("-")[1] - 1)
				  .format("YYYY-MM-DD")} 范围内`;
				this.$alert(str, this.$t('pop_up.Tips'), {
				  confirmButtonText: this.$t('pop_up.Determine'),
				});
				return false 
          }
        } else {
          let yearTime = [
            new Date(
              moment([this.$route.query.dataDate.split("-")[0], 0, 1])
                .month(0)
                .format("YYYY-MM-DD 00:00:00")
            ),
            new Date(
              moment([this.$route.query.dataDate.split("-")[0], 0, 31])
                .month(11)
                .format("YYYY-MM-DD 23:59:59")
            ),
          ];
          let startDate = new Date(date[0]).getTime();
          let endDate = new Date(date[1]).getTime();
          if (startDate < yearTime[0].getTime() || endDate > yearTime[1].getTime()) {
            this.datetime = yearTime;
            let str = `请选择时间 ${moment([
              this.$route.query.dataDate.split("-")[0],
              0,
              1,
            ])
              .month(0)
              .format("YYYY-MM-DD")} ~ ${moment([
              this.$route.query.dataDate.split("-")[0],
              0,
              31,
            ])
              .month(11)
              .format("YYYY-MM-DD")} 范围内`;
            this.$alert(str, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return false 
          }
        }
      }
    },
    echartsInit() {
      let url = "/acb/2.0/inspectionStatistics/checktypedata";
      this.$axios
        .get(url, {
          data: {
            managerId: this.$route.query.managerId,
            dateType: this.$route.query.dateType,
            dataDate: this.$route.query.dataDate,
          },
        })
        .then((res) => {
          let ar = [];
          let obj = {};
          obj.name = "处罚次数";
          res.value.forEach((ele) => {
            this.legData.push(ele.checkName);
            ar.push(ele.checkNum * 1);
          });
          obj.data = ar;
          obj.type = "bar";
          obj.barWidth = "60%";
          this.echartsArr.push(obj);
          let echarts = this.$echarts.init(
            document.querySelector("#auditStatisticsEcharts")
          );
          let option = {
            color: ["#3398DB"],
            tooltip: {
              trigger: "axis",
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                data: this.legData,
                axisTick: {
                  alignWithLabel: true,
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: this.echartsArr,
          };
          echarts.setOption(option);
        });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    searchData() {
      if (this.datetime) {
        this.formInline.startTime =
          this.datetime && this.datetime.length == 0 ? "" : this.datetime[0];
        this.formInline.endTime =
          this.datetime && this.datetime.length == 0 ? "" : this.datetime[1];
        let url = "/acb/2.0/inspectionStatistics/basictable";
        this.$axios
          .get(url, {
            data: {
              managerId: this.$route.query.managerId
                ? this.$route.query.managerId
                : "",
              parkId: this.formInline.parkId ? this.formInline.parkId : "",
              checkCode: this.formInline.InspectionNumber
                ? this.formInline.InspectionNumber
                : "",
              startDate: moment(this.formInline.startTime).format("YYYY-MM-DD"),
              endDate: moment(this.formInline.endTime).format("YYYY-MM-DD"),
              checkType: this.formInline.violationType,
              page: this.page,
              pageSize: this.pageSize,
            },
          })
          .then((res) => {
            this.loading = false;
            if (this.tableData == "") {
              this.total = 0;
            }
            if (res.state == 0) {
              this.tableData = res.value.list;
              this.total = res.value.total * 1;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      } else {
        this.$alert("请选择时间", "提示");
      }
    },
    workDetail() {
      let url = "/acb/2.0/pdaManager/get/" + this.$route.query.managerId;
      this.$axios.get(url).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.oldData = res.value;
        this.reInline = res.value;
        //   this.formInline.equipmentId = this.formInline.equipmentId == 0 ? '' : this.formInline.equipmentId;
        //   this.oldEquipmentId = this.formInline.equipmentId;
        //   this.oldOperationId = this.formInline.operationId;
        //   this.selected = this.formInline['parkIds'].split(',');
      });
    },
  },
  created() {},
  mounted() {
    let ar = [];
    if (this.$route.query.dataDate.length > 4) {
      let dataDate = this.$route.query.dataDate;
      ar = [
        new Date(
          moment([dataDate.split("-")[0], 0, 1])
            .month(dataDate.split("-")[1] - 1)
            .format("YYYY-MM-DD 00:00:00")
        ),
        new Date(
          moment([dataDate.split("-")[0], 0, 31])
            .month(dataDate.split("-")[1] - 1)
            .format("YYYY-MM-DD 23:59:59")
        ),
      ];
    } else {
      ar = [
        new Date(
          moment([this.$route.query.dataDate.split("-")[0], 0, 1])
            .month(0)
            .format("YYYY-MM-DD 00:00:00")
        ),
        new Date(
          moment([this.$route.query.dataDate.split("-")[0], 0, 31])
            .month(11)
            .format("YYYY-MM-DD 23:59:59")
        ),
      ];
    }
    this.datetime = ar;
    this.formInline = this.$route.query;
    this.searchData();
    this.getObeyList();
    this.echartsInit();
    this.workDetail();
  },
  components: {
    infoTable,
    TitleModule,
  },
};
</script>

<style></style>
